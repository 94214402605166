import { map, slice } from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'

import { Primary, PrimaryDark } from '../../constants/color'
import { space } from '../../constants/length'
import { textSize } from '../../constants/text'
import { viewport } from '../../constants/viewport'

interface DescriptionType {
  title: string
  content: string | string[]
}

export interface DoctorTeamDataType {
  doctorImageUrl: string
  doctorImageAlt?: string
  title: string
  name: string
  descriptions: DescriptionType[]
}

interface ExpandableType {
  expand?: boolean
}

export interface DoctorTeamProps extends ExpandableType, DoctorTeamDataType {
  onClick?: () => void
}

/**
 * Expand desktop, Normal: 上圖下文，只限定圖片高度
 * Expand mobile: 左圖右文，只限定圖片寬度
 * 圖片位置統一向上置中排列
 */
const expandDesktopImageWidth = 208
const expandMobileImageWidth = 97
const normalImageWidth = 240

const expandImageSize = css`
  width: ${expandDesktopImageWidth}px;
  @media (max-width: ${viewport.tablet}px) {
    width: ${expandMobileImageWidth}px;
  }
`
const normalImageSize = css`
  width: ${normalImageWidth}px;
  height: 260px;
  object-fit: cover;
  object-position: 50% -20px;
`
const Image = styled.img<{ expand: boolean }>`
  ${props => (props.expand === true ? expandImageSize : normalImageSize)}
  margin: 0 auto;
`

const expandWrapperStyle = css`
  flex-direction: column;
  @media (max-width: ${viewport.tablet}px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
const normalWrapperStyle = css`
  flex-direction: column;
  &:hover {
    ${Image} {
      background-size: 110%;
    }
  }
`
const Wrapper = styled.div<ExpandableType>`
  display: flex;
  ${props => (props.onClick ? 'cursor: pointer' : '')}
  ${props => (props.expand === true ? expandWrapperStyle : normalWrapperStyle)}
`

const horizontalInfoStyle = css`
  @media (max-width: ${viewport.tablet - 1}px) {
    padding: 0 ${space.m}px;
    width: calc(100% - ${expandMobileImageWidth}px);
  }
`
const Infos = styled.div<ExpandableType>`
  padding: ${space.m}px;
  ${props => (props.expand === true ? horizontalInfoStyle : '')}
`
const TitleAndName = styled.div<ExpandableType>`
  font-size: ${textSize.h3Web}px;
  padding-bottom: ${space.s}px;
  margin-bottom: ${space.s}px;
  border-bottom: 2px solid ${Primary};
  overflow: ${props => (props.expand === true ? 'visible' : 'hidden')};
  white-space: ${props => (props.expand === true ? 'normal' : 'nowrap')};
  text-overflow: ellipsis;
  display: flex;
  flex-direction: ${props => (props.expand === true ? 'row' : 'column')};
  @media (max-width: ${viewport.tablet - 1}px) {
    flex-direction: column;
  }
`
const Title = styled.h3`
  //colors fine-tuning to improve the accessibility of web app
  color: #93237c;
  font-weight: 400;
  margin: 0 8px 0 0;
`
const Name = styled.h3`
  color: ${PrimaryDark};
  font-weight: 400;
  margin: 0;
`

const contentLimitStyle = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`
const SpecialtyTitle = styled.div`
  //colors fine-tuning to improve the accessibility of web app
  color: #93237c;
  margin: ${space.s}px 0;
`
const SpecialtyContent = styled.p<ExpandableType>`
  ${props => (props.expand === false ? contentLimitStyle : '')}
  margin-bottom: 0;
`
const DescriptionWrap = styled.div`
  font-size: ${textSize.h4Web}px;
  line-height: 2;
`

export const DoctorTeam = (props: DoctorTeamProps) => {
  const { doctorImageUrl, doctorImageAlt, title, name, descriptions, onClick, expand = true } = props
  return (
    <Wrapper onClick={onClick} expand={expand}>
      <Image src={doctorImageUrl} expand={expand} alt={doctorImageAlt} loading='lazy' placeholder='#ffe1f9' />
      <Infos expand={expand}>
        <TitleAndName expand={expand}>
          <Title>{title} </Title>
          <Name>{name} 醫師</Name>
        </TitleAndName>
        <DescriptionWrap>
          {map(
            ({ title, content }) => (
              <React.Fragment key={title}>
                <SpecialtyTitle>{title}</SpecialtyTitle>
                {content instanceof Array ? (
                  map(
                    item => (
                      <SpecialtyContent key={item} expand={expand}>
                        {item}
                      </SpecialtyContent>
                    ),
                    content
                  )
                ) : (
                  <SpecialtyContent expand={expand}>{content}</SpecialtyContent>
                )}
              </React.Fragment>
            ),
            expand ? descriptions : slice(0, 1, descriptions)
          )}
        </DescriptionWrap>
      </Infos>
    </Wrapper>
  )
}

export default DoctorTeam
