import { Link } from 'gatsby-plugin-intl'
import { addIndex, map } from 'ramda'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Primary, White } from '../../constants/color'
import { space } from '../../constants/length'
import { textSize } from '../../constants/text'
import { viewport } from '../../constants/viewport'
import { hexToRGBA } from '../../utils/color'
import { Hoverable } from '.'

const mapIndexed = addIndex<string>(map)

export const SpecialistWrapper = styled(Link)`
  background-color: ${White};
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  @media (max-width: ${viewport.tablet - 1}px) {
    flex-direction: row;
  }
`

const ImageWrapper = styled.div<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
  background-position: center;
  background-size: cover;

  @media (min-width: ${viewport.tablet}px) {
    height: 256px;
    width: 100%;
  }
  @media (min-width: ${viewport.mobile}px) and (max-width: ${viewport.tablet - 1}px) {
    height: 128px;
    width: 170px;
  }
  @media (max-width: ${viewport.mobile - 1}px) {
    height: 128px;
    width: 50%;
  }
`

const activeStyle = css`
  display: flex;
`
const baseStyle = css`
  display: none;
`

const DescriptionWrapper = styled.div<Hoverable>`
  align-items: center;
  background-color: ${hexToRGBA(Primary, 0.7)};
  color: ${White};
  flex-direction: column;
  height: 100%;
  justify-content: center;

  @media (min-width: ${viewport.tablet}px) {
    ${props => (props.isHovering ? activeStyle : baseStyle)}
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`

const Title = styled.div`
  //colors fine-tuning to improve the accessibility of web app
  color: #8f317b;

  @media (min-width: ${viewport.tablet}px) {
    font-size: ${textSize.h3Web}px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    font-size: ${textSize.h3Mobile}px;
    line-height: 1.88;
  }
`

const Subtitle = styled.div``
const SubtitleListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${textSize.body}px;

  ${Subtitle} {
    margin-bottom: ${space.s}px;
  }
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${viewport.tablet}px) {
    align-items: center;
    height: 78px;
    justify-content: center;

    ${SubtitleListWrapper} {
      display: none;
    }
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    padding-top: ${space.s}px;
    padding-left: ${space.m}px;

    ${Title} {
      margin-bottom: ${space.s}px;
    }
  }
`

export interface SpecialistProps {
  imageUrl: string
  title: string
  descriptionList: string[]
  url: string
}

const Specialist = (props: SpecialistProps) => {
  const { imageUrl, descriptionList, title, url } = props
  const [isHovering, setIsHovering] = useState(false)

  return (
    <SpecialistWrapper onMouseOver={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} to={url}>
      <ImageWrapper imageUrl={imageUrl}>
        <DescriptionWrapper isHovering={isHovering}>
          {mapIndexed(
            (description, index) => (
              <div key={`specialist-menu-item-description-${index}`}>{description}</div>
            ),
            descriptionList
          )}
        </DescriptionWrapper>
      </ImageWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <SubtitleListWrapper>
          {mapIndexed(
            (description, index) => (
              <Subtitle key={`specialist-submenu-item-description-${index}`}>{description}</Subtitle>
            ),
            descriptionList
          )}
        </SubtitleListWrapper>
      </TextWrapper>
    </SpecialistWrapper>
  )
}

export default Specialist
