import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import DoctorImgBackground from '../../../assets/images/doctorImg_background.svg'
import { space } from '../../../constants/length'
import { Primary } from '../../../constants/newColor'
import { H2, Tag } from '../../../constants/newText'
import { Location } from '../../../types/types'
import { GridItem, GridLayout, GridLayoutProps } from '../../Grid'
import Tags from '../../Tags'

export interface ImageAndTitleType {
  doctorImageUrl?: string
  title?: string
  name: string
  location: Location[]
}

const Wrapper = styled.div``
const imgHeight = 200
const Title = styled.div`
${Tag}
  border: 1px solid ${Primary};
  color: ${Primary};
  width: 120px;
  padding: ${space.s}px;
  text-align: center;
  box-shadow: 3px -3.5px 0 black;
`

const Name = styled.h2`
${H2}
  color: ${Primary};
  margin: ${space.s}px 0 0 0;
  text-align: left;
  span {
    font-size: 14px;
    margin-right: ${space.s}px;
  }
`

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
`

export const DoctorImgWrapper = styled.div`
  width: 176px;
  height: 200px;
  position: relative;
  background-image: url(${DoctorImgBackground});
  background-repeat: no-repeat;
`

export const DoctorImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 138px;
  height: ${imgHeight}px;
  border: 1px solid ${Primary};
  border-radius: 96px;
  object-fit: cover;
`

type ImageAndTitleProps = ImageAndTitleType & GridLayoutProps

const ImageAndTitle = (props: ImageAndTitleProps) => {
  const { title, name, location, doctorImageUrl, templateColumns, templateAreas } = props
  const { formatMessage } = useIntl()
  const tagMargin = '8px 8px 0 0'
  const missingDoctorImg = require(`../../../assets/static/doctors/missing_default.png`)

  return (
    <Wrapper>
      <GridLayout templateColumns={templateColumns} templateAreas={templateAreas}>
        <GridItem area='title'>
          <Title>{title}</Title>
        </GridItem>
        <GridItem area='name'>
          <Name>
            <span>Dr.</span>
            {name}
          </Name>
        </GridItem>
        <GridItem area='tags'>
          <TagsWrapper>
            {location.map((value: Location) => (
              <Tags m={tagMargin} key={value} type={value} title={formatMessage({ id: `location.${value}` })} />
            ))}
          </TagsWrapper>
        </GridItem>
        <GridItem area='img'>
          <DoctorImgWrapper>
            <DoctorImage src={doctorImageUrl || missingDoctorImg} alt={`${title}-${name}醫師`} loading='lazy' placeholder='#ffe1f9' />
          </DoctorImgWrapper>
        </GridItem>
      </GridLayout>
    </Wrapper>
  )
}

export default ImageAndTitle
