import { Link } from 'gatsby'
import React, { FC, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { Primary, White } from '../../../constants/newColor'
import { Caption, H3 } from '../../../constants/newText'
import { Hoverable } from '..'

const StyledNewsLink = styled(Link)``

const borderClose = keyframes`
from {
   width: 40px;
   bottom: -2px;
  }
  to {
   width: 0px;
   bottom: 1px;
}
`

export const SpecialNewsWrapper = styled.div<Hoverable>`
  border: 1px solid ${Primary};
  border-radius: 102px;
  padding: 24px 38px;
  margin: 24px auto;
  position: relative;
  transition: 0.3s;
  box-shadow: ${props => (props.isHovering ? '0px 4px 0 black' : 'none')};
  ::after {
    content: '';
    display: block;
    width: 40px;
    height: 20px;
    position: absolute;
    z-index: 3;
    bottom: -5px;
    right: 25px;
    background-color: ${White};
    animation: ${props =>
      props.isHovering
        ? css`
            ${borderClose} 0.05s linear alternate forwards
          `
        : `none`};
  }
`

const Title = styled.h3`
  ${H3}
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

const Date = styled.div<Hoverable>`
  ${Caption}
  display: inline-block;
  background-color: ${White};
  position: absolute;
  padding: 8px;
  top: -17px;
  transition: 0.3s;
  left: ${props => (props.isHovering ? '50%' : '30px')};
  transform: ${props => (props.isHovering ? 'translate(-50%, 0%)' : 'none')};
`

export interface SpecialNewsProps {
  newsId: string
  alias?: string
  title: string
  date: string
}

const SpecialNews: FC<SpecialNewsProps> = props => {
  const { newsId, alias, title, date } = props
  const [isHovering, setIsHovering] = useState(false)
  return (
    <StyledNewsLink to={alias ? `/news/${alias}` : `/news/${newsId}`}>
      <SpecialNewsWrapper onMouseOver={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} isHovering={isHovering}>
        <Date isHovering={isHovering}>{date}</Date>
        <Title>{title}</Title>
      </SpecialNewsWrapper>
    </StyledNewsLink>
  )
}

export default SpecialNews
