import React from 'react'
import styled from 'styled-components'

import { space } from '../../../constants/length'
import { Primary } from '../../../constants/newColor'
import { H4, Body as _Body } from '../../../constants/newText'
import ImageAndTitle, { ImageAndTitleType } from './ImageAndTitle'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemTitle = styled.h4`
  ${H4}
`

const Strength = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${space.l}px 0;
`
const Body = styled.p`
  ${_Body}
  text-align: left;
  white-space: pre-line;
`
const Experience = styled.div`
  padding: ${space.m}px;
  border: 1px solid ${Primary};
  border-radius: ${space.s}px;
  box-shadow: 1px 2px 0 black;
`

export type NewDoctorTeamType = ImageAndTitleType & {
  strength?: string
  experience?: string
}

export const NewDoctorTeam = (props: NewDoctorTeamType) => {
  const { name, title, location, doctorImageUrl, strength, experience } = props

  return (
    <Wrapper>
      <ImageAndTitle
        name={name}
        title={title}
        location={location}
        doctorImageUrl={doctorImageUrl}
        templateColumns={['auto 176px']}
        templateAreas={[`'. img' 'title img' 'name img' 'tags img' '. img'`]}
      />
      <Strength>
        <ItemTitle>主治專長</ItemTitle>
        <Body>{strength}</Body>
      </Strength>
      <Experience>
        <ItemTitle>學經歷</ItemTitle>
        <Body>{experience}</Body>
      </Experience>
    </Wrapper>
  )
}

export default NewDoctorTeam
