import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import LatestCardBackground from '../../../assets/images/latestCard-background.svg'
import MapIcon from '../../../assets/images/maps.jpg'
import { space } from '../../../constants/length'
import { Primary, Secondary, Taipei } from '../../../constants/newColor'
import { H3, Tag as _Tag } from '../../../constants/newText'
import { viewport } from '../../../constants/viewport'
import { getClinicInfo } from '../../Clinic/getClinicInfo'
import { GridItem, GridLayout } from '../../Grid'
import { PatientCaseProps } from './PatientCase'

interface LocationProps {
  color?: string
}

const StyledPatientCaseLink = styled(Link)``

export const LatestCaseWrapper = styled.div`
  border: 1px solid ${Primary};
  border-radius: ${space.s}px;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    bottom: -4px;
    left: 0;
    border: 1px solid ${Primary};
    border-radius: ${space.s}px;
  }

  @media (min-width: ${viewport.desktop}px) {
    background-image: url(${LatestCardBackground});
    background-position: bottom 2rem left 1.5rem;
    background-repeat: no-repeat;
    min-height: 280px;
    padding: auto;
  }
  @media (min-width: ${viewport.tablet}px) and (max-width: ${viewport.desktop - 1}px) {
    background-image: none;
    padding: ${space.l}px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    background-image: none;
    margin: 0 ${space.s}px;
  }
`
const Img = styled.img`
  object-fit: cover;
  position: center;
  border: 1px solid ${Primary};
  border-radius: 5px;

  @media (min-width: ${viewport.desktop}px) {
    width: 230px;
    height: 230px;
    margin: auto 0;
  }
  @media (min-width: ${viewport.tablet}px) and (max-width: ${viewport.desktop - 1}px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    width: 289px;
    height: 289px;
    margin: 0 auto;
  }
`
const Heading = styled.div<LocationProps>`
  color: ${Secondary};
  &:after {
    content: '';
    display: block;
    height: 4px;
    width: 25%;
    background-color: ${props => (props.color ? props.color : Taipei)};
    border-radius: 4px;
  }

  @media (min-width: ${viewport.desktop}px) {
    margin-top: 0;
    font-size: 30px;
  }
  @media (min-width: ${viewport.tablet}px) and (max-width: ${viewport.desktop - 1}px) {
    margin-top: 0;
    font-size: 28px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    margin-top: ${space.m}px;
    font-size: 24px;
  }
`
const DateAndLocationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (min-width: ${viewport.desktop}px) {
    margin-bottom: 0;
  }
  @media (min-width: ${viewport.tablet}px) and (max-width: ${viewport.desktop - 1}px) {
    margin-bottom: 0;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    margin-bottom: ${space.m}px;
  }
`
const Title = styled.h3`
  ${H3}
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`
const Category = styled.span`
  ${_Tag}
  display: flex;
  &:before {
    content: '#';
    display: block;
    margin-right: 0.2rem;
  }
`
const Location = styled.span<LocationProps>`
  ${_Tag}
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.color ? props.color : Taipei)};
  @media (min-width: ${viewport.desktop}px) {
    font-size: 1.3rem;
  }
  @media (min-width: ${viewport.tablet}px) and (max-width: ${viewport.desktop - 1}px) {
    font-size: 1rem;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    font-size: 1rem;
  }
`
const Tag = styled.span`
  ${_Tag}
`
const Map = styled.div<LocationProps>`
  height: 16px;
  width: 16px;
  margin-right: 4px;
  justify-self: center;
  background-image: url(${MapIcon});
  background-color: ${props => (props.color ? props.color : Taipei)};
  background-blend-mode: lighten;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

type Props = PatientCaseProps
const LatestCase = (props: Props) => {
  const { caseId, imageUrl, publishedDate, title, alias, location, category } = props
  const { formatMessage } = useIntl()

  return (
    <StyledPatientCaseLink to={alias ? `/case/${alias}` : `/case/${caseId}`}>
      <LatestCaseWrapper>
        <GridLayout
          templateAreas={[
            `
        '. top .'
        '. img  .'
        '. img .'
        '. img .'
        '. middle .'
        '. bottom .'
            `,
            `
    'img img img top top top'
    'img img img middle middle middle'
    'img img img bottom bottom bottom'
            `,
            `
    '. img img top top top .'
    '. img img middle middle middle .'
    '. img img bottom bottom bottom .'
            `
          ]}
          templateColumns={['24px 1fr 24px', 'repeat(6, 1fr)', '80px repeat(5, 1fr) 24px']}
          columnGap={['unset', '24px', '16px']}
          rowGap={['16px', 'unset', 'unset']}
        >
          <GridItem area='img'>
            <Img src={imageUrl} alt={title} />
          </GridItem>
          <GridItem area='top'>
            <Heading color={getClinicInfo({ location: location || 'TAIPEI', value: 'primaryColor' })}>最新文章</Heading>
          </GridItem>
          <GridItem area='middle'>
            <div>
              <Title>{title}</Title>
              <Category>{formatMessage({ id: `case.${category}` })}</Category>
            </div>
          </GridItem>
          <GridItem area='bottom'>
            <DateAndLocationWrapper>
              <Tag>{publishedDate}</Tag>
              <Location color={getClinicInfo({ location: location || 'TAIPEI', value: 'primaryColor' })}>
                <Map color={getClinicInfo({ location: location || 'TAIPEI', value: 'primaryColor' })} />
                {formatMessage({ id: `location.${location || 'TAIPEI'}` })}
              </Location>
            </DateAndLocationWrapper>
          </GridItem>
        </GridLayout>
      </LatestCaseWrapper>
    </StyledPatientCaseLink>
  )
}

export default LatestCase
