import { Link, useIntl } from 'gatsby-plugin-intl'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import ImgDecoration from '../../../assets/images/img-decoration.svg'
import MapIcon from '../../../assets/images/maps.jpg'
import { space } from '../../../constants/length'
import { Primary, Taipei } from '../../../constants/newColor'
import { Caption, H3, Tag } from '../../../constants/newText'
import { viewport } from '../../../constants/viewport'
import { CaseCategory } from '../../../types/types'
import { getClinicInfo } from '../../Clinic/getClinicInfo'

interface ClinicColorProps {
  color?: string
}

export const PatientCaseWrapper = styled.div`
  color: ${Primary};
  display: flex;
  margin: ${space.m}px auto;
  @media (min-width: ${viewport.tablet}px) {
    flex-direction: column;
    max-width: 205px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    flex-direction: row;
    align-items: stretch;
    min-width: 323px;
  }
`

const imgSize = 200
const offset = 2

const ImgStyle = css`
  border: 1px solid ${Primary};
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
`

const ImgWrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10%;
    left: -5%;
    height: 20%;
    width: 20%;
    background-image: url(${ImgDecoration});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
  }
  @media (min-width: ${viewport.tablet}px) {
    min-width: ${imgSize}px;
    min-height: ${imgSize}px;
    margin-bottom: ${space.m}px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    min-width: ${imgSize / 1.5}px;
    min-height: ${imgSize / 1.5}px;
    margin-right: ${space.m}px;
  }
`

const Img = styled.img`
  ${ImgStyle}
  object-fit: cover;
  bottom: ${offset}px;
  right: ${offset}px;
  z-index: 1;
`

const ImgBackground = styled.div<ClinicColorProps>`
  ${ImgStyle}
  background-color: ${props => (props.color ? props.color : Taipei)};
  top: ${offset}px;
  left: ${offset}px;
  z-index: -1;
`

const TagsAndLocationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TagList = styled.div`
  display: flex;
`

const Category = styled.span`
  ${Tag}
  display: flex;
  &:before {
    content: '#';
    display: block;
    margin-right: 0.2rem;
  }
`

const Location = styled.span<ClinicColorProps>`
${Tag}
  color: ${props => (props.color ? props.color : Taipei)};
  display: flex;
  align-items: center;
  &:before {
    content: '';
    display: block;
    height: 14px;
    width: 14px;
    margin-right: 4px;
    justify-self: center;
    background-image: url(${MapIcon});
    background-color: ${props => (props.color ? props.color : Taipei)};
    background-blend-mode: lighten;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
`

const DoctorAndDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Doctor = styled.span`
  ${Tag}
  display: flex;
  border: 1px solid ${Primary};
  padding: 8px;
  border-radius: 40px;
  color: ${Primary};
`

const Title = styled.h3`
  ${H3}
  text-align: left;
  margin: ${space.m}px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  @media (min-width: ${viewport.tablet}px) {
    margin: ${space.m}px 0;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    margin: ${space.s}px 0;
  }
`

const ContentWrapper = styled.div`
  @media (max-width: ${viewport.tablet - 1}px) {
    width: 100%;
    min-width: 165px;
    justify-self: flex-end;
  }
`

const PublicDate = styled.p`
  ${Caption}
`

interface WrapperProps {
  sidespace?: number
}
const StyledPatientCaseLink = styled(Link)<WrapperProps>`
  margin: ${props => (props.sidespace ? `0 ${props.sidespace}px` : '0')};
`

export interface PatientCaseProps {
  caseId: string
  alias?: string
  category: CaseCategory
  imageUrl: string
  doctor?: string
  publishedDate: string
  title: string
  location?: string
}

type Props = PatientCaseProps & WrapperProps

const PatientCase: FC<Props> = props => {
  const { caseId, imageUrl, doctor, publishedDate, title, alias, location, category, sidespace } = props
  const { formatMessage } = useIntl()

  return (
    <StyledPatientCaseLink to={alias ? `/case/${alias}` : `/case/${caseId}`} sidespace={sidespace}>
      <PatientCaseWrapper>
        <ImgWrapper>
          <Img src={imageUrl} alt={title} loading='lazy' placeholder='#ffe1f9' />
          <ImgBackground color={getClinicInfo({ location: location || 'TAIPEI', value: 'primaryColor' })} />
        </ImgWrapper>
        <ContentWrapper>
          <TagsAndLocationWrapper>
            <TagList>
              <Category>{formatMessage({ id: `case.${category}` })}</Category>
            </TagList>
            <Location color={getClinicInfo({ location: location || 'TAIPEI', value: 'primaryColor' })}>{formatMessage({ id: `location.${location || 'TAIPEI'}` })}</Location>
          </TagsAndLocationWrapper>
          <Title>{title}</Title>
          <DoctorAndDateWrapper>
            <Doctor>{!doctor || doctor === 'none' ? '無指定醫師' : `${doctor}醫師`}</Doctor>
            <PublicDate>{publishedDate}</PublicDate>
          </DoctorAndDateWrapper>
        </ContentWrapper>
      </PatientCaseWrapper>
    </StyledPatientCaseLink>
  )
}

export default PatientCase
