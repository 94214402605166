import { Link } from 'gatsby-plugin-intl'
import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'

import { space } from '../../../constants/length'
import { DarkGrey, LightBG, Primary, Secondary } from '../../../constants/newColor'
import { Body, H3, Tag } from '../../../constants/newText'
import { Hoverable } from '..'

export const NewsWrapper = styled(Link)`
  border: 1px solid ${Primary};
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 1);
  display: block;
`
const ImgWrapper = styled.div`
  width: 100%;
  height: 182px;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 1px solid ${Primary};
  border-radius: 8px 8px 0 0;
`
const Date = styled.span`
${Tag}
  color: ${DarkGrey};
  margin-bottom: 1rem;
`

const baseStyle = css`
  color: ${Primary};
`
const hoverStyle = css`
  color: ${DarkGrey};
`

const Title = styled.h3<Hoverable>`
  ${H3}
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left;
  color: ${Secondary};

  ${props => (props.isHovering ? hoverStyle : baseStyle)}
`
const TextWrapper = styled.div`
  padding: ${space.m}px;
`
const ContentWrapper = styled.p`
  ${Body}
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding: 0;
  text-align: left;
`

export interface NewsProps {
  date: string
  newsId: string
  title: string
  description?: string
  alias?: string
  image?: string
  thumbnailImage: string
  thumbnailAlt: string
}

const News: FC<NewsProps> = props => {
  const { date, newsId, title, alias, thumbnailImage, thumbnailAlt, description } = props
  const [isHovering, setIsHovering] = useState(false)

  return (
    <NewsWrapper onMouseOver={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} to={alias ? `/news/${alias}` : `/news/${newsId}`}>
      <ImgWrapper>
        <Image src={thumbnailImage} alt={thumbnailAlt} loading='lazy' placeholder={LightBG} />
      </ImgWrapper>
      <TextWrapper>
        <Date>{date}</Date>
        <Title isHovering={isHovering}>{title}</Title>
        <ContentWrapper>{description}</ContentWrapper>
      </TextWrapper>
    </NewsWrapper>
  )
}

export default News
